import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './locales/en/translation.json'
import fr from './locales/fr/translation.json'

const resources = {
  en: { translation: en },
  fr: { translation: fr }
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    // debug: true,
    returnObjects: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18next
